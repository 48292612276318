import React from 'react';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const TestCarousel = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
      };
   
      return (
        <div className="image-slick-div test-parent">
          <h1>Testimonials</h1>
          <Slider {...settings}>
          <div className="test">
              <div className="test-text">
                <h4>"My house has never looked better! Fast quote good price and it was worth it! My fences and driveway also look amazing!! Great work!"</h4>
                <h3>- Lauren G</h3>
              </div>
            </div>
            <div className="test">
              <div className="test-text">
                <h4>"I was very pleased with the outcome of my services. The scheduling system was very simple, and the work done was outstanding. Will definitely be using them again in the future!"</h4>
                <h3>- Neil K</h3>
              </div>
            </div>
            <div className="test">
              <div className="test-text">
                <h4>"Mark was awesome to work with. He did above and beyond work and went out of his to make sure things were done the right way. Plus the results were amazing!! 10/10 recommend!!"</h4>
                <h3>- Alex C</h3>
              </div>
            </div>
            <div className="test">
              <div className="test-text">
                <h4>"The quote process was very convenient! Ohio Premium Pressure Washing has my patio looking brand new. Can’t wait to have them back!"</h4>
                <h3>- Matthew W</h3>
              </div>
            </div>
            <div className="test">
              <div className="test-text">
                <h4>"The driveway and sidewalk look so much better! You did such a good job of communicating on arrival/finish times too."</h4>
                <h3>- Julie B</h3>
              </div>
            </div>
            <div className="test">
              <div className="test-text">
                <h4>"We are very happy with our experience with Ohio premium pressure washing. Our house and driveway look like new, and they cleaned up around the yard after they were done. Will be using them in the future"</h4>
                <h3>- Betsy G</h3>
              </div>
            </div>
          </Slider>
        </div>
      );
  }
  export default TestCarousel;