import React from 'react';

const Footer = () => {
    return (
      <div className="footer row">
          <div className="footer-item">  
            <img  className="cert-logo" src="ECPSeal.png" alt="Exterior Cleaning Professional"></img>
          </div>
            
            <p className="footer-item copyright">Copyright © 2023 | Ohio Premium Pressure Washing</p>
          <div className="footer-item"> 
            <p className="contact-container"><a href="tel:2162599055" className="contact-link">(216) 259-9055</a><div className="divider">|</div><a className="contact-link email-foot" href = "mailto: ohiopremiumpw@gmail.com">ohiopremiumpw@gmail.com</a></p> 
          </div>  
      </div>
    );
  }
  
  export default Footer;
  