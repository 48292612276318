import {React, useState} from 'react';
// import '../App.css';
import {Navbar, Banner, Services, Concrete, QuoteForm, SmallScreenMenu, HowItWorks, Pricing, Footer, Mission, ImageCarousel, TestCarousel} from './index';
import { Routes, Route, BrowserRouter } from "react-router-dom";
import {
  NavLink
} from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
// import satisfied from '../Satisfied.svg';
import { AiOutlineForm, AiOutlineSchedule } from 'react-icons/ai';
import {MdMarkEmailUnread, MdWaterDrop, MdPayment, MdSentimentSatisfied} from 'react-icons/md';
import {BiHappy} from 'react-icons/bi';
// import Banner from './banner';
const App = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [concrete, setConcrete] = useState(false);
  const [house, setHouse] = useState(false);
  const [deck, setDeck] = useState(false);
  const [patio, setPatio] = useState(false);
  const [fence, setFence] = useState(false);
  const [additionalInfo, setAdditionalInfo] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  
  return (
    <div className="App col">
      <BrowserRouter>
        <Navbar menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
        <div className="promo">
                <h4>All jobs in March and April are 15% off!</h4>
                <div className="promo-btns">
                  {/* <button className="promo-call-btn"><a className="booking-link" href="https://book.housecallpro.com/book/Ohio-Premium-Pressure-Washing/5bb8114554bd40bd807700363c9711e2?attribution=Online%20Booking">Online Booking</a></button> */}
                  <NavLink className="quote-btn" to='/getaquote'>Online Quote</NavLink>
                </div>
                
                {/* <button className="mobile-info"><a className="booking-link" href="https://book.housecallpro.com/book/Ohio-Premium-Pressure-Washing/5bb8114554bd40bd807700363c9711e2?attribution=Online%20Booking">Online Booking</a></button> */}
        </div>

        { menuOpen ?
                  <>
                    <SmallScreenMenu menuOpen={menuOpen} setMenuOpen={setMenuOpen}/>
                  </>
                  : null
                }
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Banner/>
                {/* <Mission/> */}
                
                <div className="how-it-works hiw-root">
                <h2>Convenient Scheduling</h2>
                <div className="row main-steps works-steps">
                    <div className="works-step">
                        <AiOutlineForm className="step-icon"/>
                        <h3>1. Fill Out Online Form</h3>
                        <p>Fill out the form below or give us a call at <a href="tel:2162599055" className="contact-link contact-link-main">(216) 259-9055</a>.</p>
                    </div>
                    <div className="works-step">
                        <MdMarkEmailUnread className="step-icon"/>
                        <h3>2. We send a Quote</h3>
                        <p>No home visit required. Using remote quoting software we will send you your quote.</p>
                    </div>
                    <div className="works-step">
                        <AiOutlineSchedule className="step-icon"/>
                        <h3>3. Schedule</h3>
                        <p>Along with your quote we will also send available times. Text, email, or call to confirm.</p>
                    </div>
                    <div className="works-step">
                        <MdPayment className="step-icon"/>
                        <h3>4. Online Payment</h3>
                        <p>You don't pay until you're satisfied with the job.</p>
                    </div>
                </div>
              </div>
              <TestCarousel className="test-carousel"/>
              <div className="satisfaction">
                    <div>
                        <img className="satisfaction-jpg" src={"satisfied.png"} alt="Satisfaction Guaranteed"/>
                    </div>
                    <div className="satisfaction-p">
                        <h2>We provide a safe and effective cleaning service. You don't pay until you're happy with the job.</h2>
                    </div>
                </div>
                <ImageCarousel className="image-carousel"/>
                <QuoteForm 
                  firstName={firstName} 
                  setFirstName={setFirstName} 
                  lastName={lastName} 
                  setLastName={setLastName} 
                  email={email} 
                  setEmail={setEmail} 
                  phone={phone}
                  setPhone={setPhone}
                  concrete={concrete}
                  setConcrete={setConcrete}
                  house={house}
                  setHouse={setHouse}
                  deck={deck}
                  setDeck={setDeck}
                  patio={patio}
                  setPatio={setPatio}
                  fence={fence}
                  setFence={setFence}
                  additionalInfo={additionalInfo}
                  setAdditionalInfo={setAdditionalInfo}
                />
              </>
            }
          />
          <Route
            path="/services"
            element={
              <Services/>
            }
          />
          <Route
            path="/howitworks"
            element={
              <HowItWorks/>
            }
          />
          <Route
            path="/pricing"
            element={
              <Pricing/>
            }
          />
          <Route
            path="/getaquote"
            element={
              <QuoteForm
                  firstName={firstName} 
                  setFirstName={setFirstName} 
                  lastName={lastName} 
                  setLastName={setLastName} 
                  email={email} 
                  setEmail={setEmail} 
                  phone={phone}
                  setPhone={setPhone}
                  concrete={concrete}
                  setConcrete={setConcrete}
                  house={house}
                  setHouse={setHouse}
                  deck={deck}
                  setDeck={setDeck}
                  patio={patio}
                  setPatio={setPatio}
                  fence={fence}
                  setFence={setFence}
                  additionalInfo={additionalInfo}
                  setAdditionalInfo={setAdditionalInfo}
              />
            }
          />
        </Routes>
        <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;
