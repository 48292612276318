import React from 'react';
import {NavLink} from 'react-router-dom';

const Banner = () => {
    return (
      <div className="banner">
        <div className="banner-btn-container">
          <h1>About Us</h1>
          <h3>Ohio Premium Pressure Washing is a local owner-operated business, offering pressure washing services to Cuyahoga and surrounding counties. Our mission is to provide the highest quality exterior cleaning, while making quoting, scheduling, and payments convenient for our customers.</h3>
          <NavLink className="quote-btn banner-btn" to='/getaquote'>Free Online Quote</NavLink>
        </div>
      </div>
    );
  }
  
  export default Banner;
  