import React from 'react';
import { AiOutlineForm, AiOutlineSchedule } from 'react-icons/ai';
import {MdMarkEmailUnread, MdWaterDrop} from 'react-icons/md';
import {BiHappy} from 'react-icons/bi';



const HowItWorks = () => {
    return (
      <div className="how-it-works">
        <h1>How It Works</h1>
        <div className="col works-steps">
            <div className="works-step">
                <AiOutlineForm className="step-icon"/>
                <h3>1. Fill Out Online Form</h3>
                <p>Fill out our Free Online Quote form.</p>
            </div>
            <div className="works-step">
                <MdMarkEmailUnread className="step-icon"/>
                <h3>2. We send a Quote</h3>
                <p>After using our remote quoting software we will send a quote.</p>
            </div>
            <div className="works-step">
                <AiOutlineSchedule className="step-icon"/>
                <h3>3. Schedule</h3>
                <p>Depending on your preference we can schedule via text, email, or phone call.</p>
            </div>
            <div className="works-step">
                <MdWaterDrop className="step-icon"/>
                <h3>4. Water Access</h3>
                <p>You do not need to be home when we are cleaning your property. All we need is access to your exterior water source.</p>
            </div>
            <div className="works-step">
                <BiHappy className="step-icon"/>
                <h3>5. Enjoy!</h3>
                <p>Enjoy your sparkling home!</p>
            </div>
        </div>
        <script type='text/javascript' src='https://www.housecallpro.com/assets/BookingBar.js?company=5bb8114554bd40bd807700363c9711e2&path=Ohio-Premium-Pressure-Washing'></script>
      </div>
    );
  }
  
  export default HowItWorks;
  