import {React, useRef} from 'react';
import HubspotForm from 'react-hubspot-form'
const QuoteForm = (props) => {
    return (
      <div className="quote-form-container padding-top">
        <h1>Request a Free Quote</h1>
        <script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/embed/v2.js"></script>
        <HubspotForm
                portalId='23656045'
                formId='29fb1ef2-49fb-47b5-a70f-c48be6145bc7'
                onSubmit={() => console.log('Submit!')}
                onReady={(form) => console.log('Form ready!')}
                loading={<div>Loading...</div>}
            />
      </div>
    );
  }
  
  export default QuoteForm;
  
